import {ContentBlock, ContentColumn, ImgSmall, TextColumn, Title} from "../GeneralComponents";
import React from "react";
import equipement from "../../resources/equipement.jpg";

const Equipement = () => {
    return (
        <>
            <ContentBlock>
                <Title>
                    Matériel
                </Title>
                <ContentColumn>
                    <p>
                        Les fards à l'eau utilisés répondent aux exigences de la législation
                        européenne sur les cosmétiques. Les couleurs sont sans gluten,
                        sans parabène, sans parfum et non testées sur les animaux.
                        Ils sont adaptés à une application sur la peau des enfants
                        à partir de l'âge de 3 ans.
                    </p>
                    <ImgSmall src={equipement} alt="Paintbrush"/>
                    <TextColumn>
                        <p>Site web : <a href="https://www.instagram.com/louis_pasquier_/">Louis Pasquier</a></p>
                        <p>Photos de présentations : <a href="https://www.instantsimages.ch/">Instant Images</a></p>
                    </TextColumn>
                </ContentColumn>
            </ContentBlock>
        </>
    );
}

export default Equipement;
