import React from "react";
import styled from "styled-components";
import { Element } from 'react-scroll';

import phoneIcon from '../resources/icons/phone.png'
import instaIcon from '../resources/icons/instagram.png'
import emailIcon from '../resources/icons/email.png'
import {ImgMini} from "./GeneralComponents";
import intfps_logo from "../resources/INTFPS_logo.png";

const ContactFooter = () => {
    return (
        <Element id="contact">
            <Footer>
                <FooterElt>
                    <ImgMini src={intfps_logo} alt="INTFSP logo"/>
                </FooterElt>
                <FooterElt>
                    Sylvie Pasquier
                </FooterElt>
                <RightBlock>
                    <FooterElt>
                        <ButtonIcon src={phoneIcon} alt="Phone icon" onClick={phoneButtonClick}/>
                        <IconText>079 501 07 38</IconText>
                    </FooterElt>
                    <FooterElt>
                        <ButtonIcon src={instaIcon} alt="Instagram icon" onClick={instaButtonClick}/>
                        <IconText>@grimagine_bulle</IconText>
                    </FooterElt>
                    <FooterElt>
                        <ButtonIcon src={emailIcon} alt="Email icon" onClick={emailButtonClick}/>
                        <IconText>pasquier.sylvie2@gmail.com</IconText>
                    </FooterElt>
                </RightBlock>
            </Footer>
        </Element>
    );
}

export default ContactFooter

/**
 * Components
 */

const Footer = styled.div`
    bottom: 0;
    width: 100%;
    overflow: hidden;

    background: #010101;
    height: 85px;
    display: flex;
    justify-content: space-between;
    padding: 1%;
    
    align-items: center;
    color: aliceblue;
    @media screen and (max-width: 768px) {
        margin-right: 0;
    }
`;

const FooterElt = styled.div`
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 1% 3%;
    height: 100%;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
        padding: 1% 2%;
    }
`

const RightBlock = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-right: 5%;
    width: 80%;
    @media screen and (max-width: 768px) {
        width: 30%;
    }
`

const IconText = styled.p`
    margin-right: -8%;
    @media screen and (max-width: 768px) {
        display: none;
    }
`

const ButtonIcon = styled.img`
    width: 35px;
    @media screen and (max-width: 768px) {
        width: 25px;
    }
`

/**
 * Functions
 */

const instaButtonClick = () => {
    window.open("https://www.instagram.com/grimagine_bulle/", '_blank', 'noopener,noreferrer');
}

const emailButtonClick = () => {
    if (navigator.clipboard) {
        navigator.clipboard.writeText("pasquier.sylvie2@gmail.com")
        alert("L'email à été copié dans le presse papier !")
    }
}

const phoneButtonClick = () => {
    if (navigator.clipboard) {
        navigator.clipboard.writeText("079 501 07 38")
        alert("Le numéro de téléphone à été copié dans le presse papier !")
    }
}
